<template>
  <div>
    <Consulta
        ref="filtroConsulta"
        titulo="Gerenciamento de Avaliadores"
        :parametros="paramsFiltro"
        :colunas="headers"
        :ordenacao-colunas="sortBy"
        :consulta-callback="consulta"
        item-key="oid"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
                v-model="paramsFiltro.nome"
                dense
                label="Nome"
                name="nomeUsuario"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.cpf"
                v-mask="'###########'"
                :counter="11"
                dense
                label="CPF"
                maxlength="11"
                name="cpfUsuario"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.email"
                dense
                label="Email"
                name="emailUsuario"
                outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
                v-model="processoAutoCompleteSelecionado"
                :items="processosAutoComplete"
                :loading="isLoadingProcessosAutoComplete"
                :search-input.sync="buscaProcessoAutoComplete"
                label="Processo"
                outlined
                dense
                item-text="nome"
                @blur="recuperaOpcoesPorProcesso"
                :append-icon="
                !isEmpty(processoAutoCompleteSelecionado) ? 'mdi-close' : ''
              "
                @click:append="clearProcessoAutoComplete"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Digite o nome de um processo...
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
                v-model="opcaoAutoCompleteSelecionada"
                :items="opcoesAutoComplete"
                :loading="isLoadingOpcoesAutoComplete"
                :search-input.sync="buscaOpcoesAutoComplete"
                label="Opção"
                outlined
                dense
                item-text="nome"
                item-value="nome"
                :append-icon="!isEmpty(paramsFiltro.nomeOpcao) ? 'mdi-close' : ''"
                @click:append="clearOpcoesAutoComplete"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Digite o nome de uma opção...
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:acoesMenu="{ item }">
        <v-list-item>
          <v-btn block text @click.prevent="selecionarAvaliador(item)">
            Alterar Opções
          </v-btn>
        </v-list-item>
      </template>
    </Consulta>
    <v-dialog
        width="800"
        v-model="avaliadorDialog"
        v-if="avaliadorDialog"
        persistent
        scrollable
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary justify-start white--text">
            Alocação de Opção
            <v-spacer/>
            <v-btn class="white--text" @click="dialog.value = false" icon>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7">
                  <v-text-field
                      v-model="avaliadorSelecionado.nome"
                      label="Nome"
                      outlined
                      readonly
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="5">
                  <v-text-field
                      v-model="avaliadorSelecionado.cpf"
                      label="CPF"
                      outlined
                      readonly
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      v-model="avaliadorSelecionado.email"
                      label="Email"
                      outlined
                      readonly
                      dense
                  ></v-text-field>
                </v-col>

                <v-card-text>
                  <v-row justify="space-between" align="center">
                    <v-sheet class="subtitle-2 ml-3">
                      <h3>Opcões</h3>
                    </v-sheet>
                    <v-dialog width="800" persistent scrollable>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" color="primary" small>
                          <v-icon left>
                            mdi-plus-thick
                          </v-icon>
                          Adicionar
                        </v-btn>
                      </template>
                      <template v-slot:default="opcoesDialog">
                        <v-card min-width="778">
                          <v-card-title
                              class="primary justify-start white--text"
                          >
                            Escolher Opções
                          </v-card-title>
                          <v-container>
                            <EscolhaProcessoAutoComplete
                                v-if="opcoesDialog.value"
                                v-model="processoEscolhido"
                                :processos="processos"
                            />
                          </v-container>
                          <SideBySideMultiSelect
                              v-if="opcoesDialog.value"
                              v-model="opcoesEscolhidas"
                              :items="opcoes"
                          />
                          <v-card-actions class="justify-center">
                            <v-btn
                                color="error"
                                @click="opcoesDialog.value = false"
                            >
                              Fechar
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="
                                salvarOpcoesDoAvaliador();
                                opcoesDialog.value = false;
                              "
                            >
                              Salvar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-data-table
                      :items="opcoesDoAvaliador"
                      :headers="headersOpcoesDoAvaliador"
                      :loading="loadingOpcoesDoAvaliador"
                      loading-text="Carregando..."
                      dense
                      class="elevation-1"
                      height="40vh"
                      :items-per-page="10"
                      fixed-header
                      :footer-props="{
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      itemsPerPageOptions: [10, 25]
                    }"
                  >
                    <template
                        v-for="header in headersOpcoesDoAvaliador.filter(
                        h => !h.transient
                      )"
                        v-slot:[`item.${header.value}`]="{ value }"
                    >
                      {{ $refs.filtroConsulta.defaultValue(header, value) }}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-dialog width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              small
                              v-bind="attrs"
                              v-on="on"
                              class="mx-2"
                              color="error"
                              icon
                              x-small
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:default="dialogConfirmarExclusao">
                          <v-card>
                            <v-card-title
                                class="primary justify-start white--text"
                            >Confirmar Exclusão
                            </v-card-title>
                            <v-card-text
                                style="font-size: 15pt; text-align: center"
                                class="mt-5 font-weight-bold"
                            >
                              Deseja Realmente Excluir essa Opção? Esta ação não
                              poderá ser desfeita!
                            </v-card-text>
                            <v-card-actions class="justify-center">
                              <v-btn
                                  color="primary"
                                  @click="
                                  excluirOpcaoDoAvaliador(item);
                                  dialogConfirmarExclusao.value = false;
                                "
                              >
                                Confirmar
                              </v-btn>
                              <v-btn
                                  color="error"
                                  @click="dialogConfirmarExclusao.value = false"
                              >Cancelar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import usuarioService from "@/services/usuario.service";
import processoService from "@/services/processo.service";
import opcaoService from "@/services/opcao.service";
import SideBySideMultiSelect from "@/components/base/SideBySideMultiSelect";
import EscolhaProcessoAutoComplete from "@/components/base/EscolhaProcessoAutocomplete";
import Consulta from "@/components/pages/admin/consultas/Consulta.vue";
import {mapActions} from "vuex";

export default {
  name: "Avaliadores",
  components: {
    EscolhaProcessoAutoComplete,
    SideBySideMultiSelect,
    Consulta
  },
  data() {
    return {
      loading: false,
      loadingOpcoesDoAvaliador: false,
      dialog: false,
      avaliadorDialog: false,
      processos: [],
      processoEscolhido: {},
      opcoes: [],
      opcoesEscolhidas: [],
      opcoesDoAvaliador: [],
      avaliadorOid: null,
      isLoadingProcessosAutoComplete: false,
      isLoadingOpcoesAutoComplete: false,
      processosAutoComplete: [],
      processoAutoCompleteSelecionado: null,
      opcoesAutoComplete: [],
      opcaoAutoCompleteSelecionada: null,
      buscaProcessoAutoComplete: null,
      buscaOpcoesAutoComplete: null,
      avaliadorSelecionado: {},
      headers: [
        {text: "Nome", value: "nome", mustSort: true},
        {text: "CPF", value: "cpf"},
        {text: "Email", value: "email"}
      ],
      headersOpcoesDoAvaliador: [
        {text: "Processo", value: "nomeProcesso"},
        {text: "Nome da Opção", value: "nomeOpcao"},
        {text: "Formação", value: "formacao", align: "center"},
        {text: "Polo", value: "polo", align: "center"},
        {text: "Turno", value: "turno", align: "center"},
        {
          text: "",
          value: "action",
          transient: true,
          align: "center",
          sortable: false
        }
      ],
      sortBy: ["nome"],
      sortDesc: [false],
      paramsFiltro: {},
      consulta: usuarioService.recuperarFiltroPorProcessoOuOpcao
    };
  },

  watch: {
    buscaProcessoAutoComplete(val) {
      this.paramsFiltro.nomeProcesso = val;

      if (val && val.length >= 3) {
        this.isLoadingProcessosAutoComplete = true;
        processoService
            .recuperarProcessoConsultaPorNome(this.paramsFiltro.nomeProcesso)
            .then(response => {
              this.processosAutoComplete = response.data;
              this.isLoadingProcessosAutoComplete = false;
            });
      }
    },

    buscaOpcoesAutoComplete(val) {
      this.paramsFiltro.nomeOpcao = val;

      if (val && val.length >= 3) {
        this.isLoadingOpcoesAutoComplete = true;
        opcaoService
            .recuperarOpcoesConsultaPorNomeOpcaoOuNomeProcesso(this.paramsFiltro)
            .then(response => {
              this.opcoesAutoComplete = response.data;
              this.isLoadingOpcoesAutoComplete = false;
            });
      }
    },

    options: {
      handler() {
        this.$nextTick(() => {
          window.scrollTo({top: 0, behavior: "smooth"});
        });

        this.getDataFromApi();
      },
      deep: true
    },

    processoEscolhido: {
      async handler() {
        if (this.processoEscolhido.oid) {
          const loader = this.$loading.show();
          await opcaoService
              .recuperarOpcoesImportacaoPorProcesso(this.processoEscolhido.oid)
              .then(response => {
                loader.hide();
                this.opcoes = response.data;
              })
              .catch(() => {
                loader.hide();
              });
        } else {
          this.opcoes = [];
        }
      }
    }
  },

  async created() {
    await processoService.recuperarProcessosConsulta().then(response => {
      this.processos = response.data;
    });
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),

    selecionarAvaliador(avaliador) {
      this.avaliadorSelecionado = avaliador;
      this.recuperarOpcoesAvaliador(avaliador.oid);
      this.avaliadorDialog = true;
    },

    recuperaOpcoesPorProcesso() {
      if (!this.isEmpty(this.processoAutoCompleteSelecionado)) {
        this.isLoadingOpcoesAutoComplete = true;
        opcaoService
            .recuperarOpcoesConsultaPorNomeOpcaoOuNomeProcesso(this.paramsFiltro)
            .then(response => {
              this.opcoesAutoComplete = response.data;
              this.isLoadingOpcoesAutoComplete = false;
            });
      }
    },

    async recuperarOpcoesAvaliador(oid) {
      this.opcoesDoAvaliador = [];
      this.avaliadorOid = oid;
      this.loadingOpcoesDoAvaliador = true;
      await opcaoService.recuperarOpcoesPorEscopoUsuario(oid).then(response => {
        this.opcoesDoAvaliador = response.data;
        this.loadingOpcoesDoAvaliador = false;
      });
    },

    async salvarOpcoesDoAvaliador() {
      const loader = this.$loading.show();
      await opcaoService
          .salvarOpcoesPorEscopoUsuario(this.avaliadorOid, this.opcoesEscolhidas)
          .then(async () => {
            await this.recuperarOpcoesAvaliador(this.avaliadorOid);
            this.exibirAviso({
              mensagem: "Sucesso ao salvar!",
              tipo: "success"
            });
            loader.hide();
          })
          .catch(() => {
            this.exibirAviso({
              mensagem: "Erro ao salvar!",
              tipo: "error"
            });
            loader.hide();
          });
    },

    async excluirOpcaoDoAvaliador(opcao) {
      const loader = this.$loading.show();
      await opcaoService
          .deletarOpcaoEscopoUsuario(this.avaliadorOid, opcao.oid)
          .then(async () => {
            await this.recuperarOpcoesAvaliador(this.avaliadorOid);
            this.exibirAviso({
              mensagem: "Opção deletada com sucesso!",
              tipo: "success"
            });
            loader.hide();
          })
          .catch(() => {
            this.exibirAviso({
              mensagem: "Erro ao deletar opção!",
              tipo: "error"
            });
            loader.hide();
          });
    },

    isEmpty(value) {
      return !value || !value.length;
    },

    clearOpcoesAutoComplete() {
      this.paramsFiltro.nomeOpcao = null;
      this.opcaoAutoCompleteSelecionada = "";
      this.opcoesAutoComplete = [];
      this.recuperaOpcoesPorProcesso();
    },

    clearProcessoAutoComplete() {
      this.processoAutoCompleteSelecionado = "";
      this.processosAutoComplete = [];
      this.opcoesAutoComplete = [];
    }
  }
};
</script>

<style scoped></style>
