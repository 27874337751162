var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"mb-1",attrs:{"justify":"center","align":"center"}},[_vm._v(" Disponíveis ")]),_c('v-text-field',{staticClass:"mx-2",attrs:{"disabled":_vm.itemsDisponiveisBusca.length === 0 && _vm.parametroBuscaDisponiveis.length === 0,"placeholder":"Busca...","append-icon":_vm.parametroBuscaDisponiveis.length ? 'mdi-close' : ''},on:{"click:append":function($event){_vm.parametroBuscaDisponiveis = ''}},model:{value:(_vm.parametroBuscaDisponiveis),callback:function ($$v) {_vm.parametroBuscaDisponiveis=$$v},expression:"parametroBuscaDisponiveis"}}),_c('v-card',{ref:"disponiveis",staticClass:"mx-auto",attrs:{"min-height":"300","min-width":"300","max-width":"300","max-height":"300"}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"two-line":"","height":"300"}},[_c('v-list-item-group',{attrs:{"value":Array.from(_vm.selecionadosDisponiveis),"active-class":"primary--text","multiple":""}},[_vm._l((_vm.itemsDisponiveisBusca),function(item,index){return [(index === 0)?_c('v-divider',{key:'dividierDisponiveisInicial'+index}):_vm._e(),_c('handle-click',{key:item.oid,on:{"single-click":function($event){return _vm.selecionaItem(item, _vm.selecionadosDisponiveis)},"double-click":function($event){return _vm.adicionaItem(item, _vm.itemsDisponiveis, _vm.itemsEscolhidos)}}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.titulo)}}),_c('v-list-item-subtitle',[_c('span',{staticClass:"text--primary",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.subTitulo)+" ")])]),_c('v-list-item-subtitle',[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.info)+" ")])])],1),_c('v-list-item-action',[(active)?_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-checkbox-blank-circle ")])],1)]}}],null,true)})],1),_c('v-divider',{key:'dividerDisponiveis'+index})]})],2)],1)],1)],1),_c('v-col',{attrs:{"align-self":"center","cols":"2"}},[_c('v-sheet',[_c('v-row',{staticClass:"mt-15 mb-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""},on:{"click":_vm.restaurar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-restore")])],1)]}}])},[_c('span',[_vm._v("Restaurar")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""},on:{"click":_vm.adicionarTodos}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1)]}}])},[_c('span',[_vm._v("Adicionar todos")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""},on:{"click":_vm.adicionarSelecionados}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Adicionar selecionados")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""},on:{"click":_vm.removerSelecionados}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Remover selecionados")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""},on:{"click":_vm.removerTodos}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-double-left")])],1)]}}])},[_c('span',[_vm._v("Remover todos")])])],1)],1)],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"mb-1",attrs:{"justify":"center","align":"center"}},[_vm._v(" Escolhidos ")]),_c('v-text-field',{staticClass:"mx-2",attrs:{"disabled":_vm.itemsEscolhidosBusca.length === 0 && _vm.parametroBuscaEscolhidos.length === 0,"placeholder":"Busca...","append-icon":_vm.parametroBuscaEscolhidos.length ? 'mdi-close' : ''},on:{"click:append":function($event){_vm.parametroBuscaEscolhidos = ''}},model:{value:(_vm.parametroBuscaEscolhidos),callback:function ($$v) {_vm.parametroBuscaEscolhidos=$$v},expression:"parametroBuscaEscolhidos"}}),_c('v-card',{staticClass:"mx-auto",attrs:{"min-height":"300","min-width":"300","max-width":"300","max-height":"300"}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"two-line":"","height":"300"}},[_c('v-list-item-group',{attrs:{"active-class":"primary--text","multiple":""},model:{value:(_vm.selecionadosEscolhidosIndex),callback:function ($$v) {_vm.selecionadosEscolhidosIndex=$$v},expression:"selecionadosEscolhidosIndex"}},[_vm._l((_vm.itemsEscolhidosBusca),function(item,index){return [(index === 0)?_c('v-divider',{key:'dividerEscolhidosInicial'+index}):_vm._e(),_c('handle-click',{key:item.oid,on:{"single-click":function($event){return _vm.selecionaItem(item, _vm.selecionadosEscolhidos)},"double-click":function($event){return _vm.adicionaItem(item, _vm.itemsEscolhidos, _vm.itemsDisponiveis)}}},[_c('v-list-item',{staticStyle:{"min-height":"auto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.titulo)}}),_c('v-list-item-subtitle',[_c('span',{staticClass:"text--primary",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.subTitulo)+" ")])]),_c('v-list-item-subtitle',[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.info)+" ")])])],1),_c('v-list-item-action',[(active)?_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-checkbox-blank-circle ")])],1)]}}],null,true)})],1),_c('v-divider',{key:'dividerEscolhidos'+index})]})],2)],1)],1)],1),(_vm.ordenaEscolhidos)?_c('v-col',{attrs:{"align-self":"center","cols":"1"}},[_c('v-sheet',[_c('v-row',{staticClass:"mt-15 mb-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":"","disabled":!_vm.itemsEscolhidos.length},on:{"click":function($event){return _vm.posicionarAcima(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-double-up")])],1)]}}],null,false,2092471529)},[_c('span',[_vm._v("Posicionar no inicio")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":"","disabled":!_vm.itemsEscolhidos.length},on:{"click":function($event){return _vm.posicionarAcima(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,false,2461999066)},[_c('span',[_vm._v("Posicionar acima")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":"","disabled":!_vm.itemsEscolhidos.length},on:{"click":function($event){return _vm.posicionarAbaixo(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2449436086)},[_c('span',[_vm._v("Posicionar abaixo")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":"","disabled":!_vm.itemsEscolhidos.length},on:{"click":function($event){return _vm.posicionarAbaixo(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-double-down")])],1)]}}],null,false,4184041253)},[_c('span',[_vm._v("Posicionar no fim")])])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }