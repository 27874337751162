<template>
  <v-container>
    <v-row justify="center">
      <v-col class="mt-1" cols="auto">
        <v-row justify="center" align="center" class="mb-1">
          Disponíveis
        </v-row>
        <v-text-field
            :disabled="itemsDisponiveisBusca.length === 0 && parametroBuscaDisponiveis.length === 0"
            class="mx-2"
            v-model="parametroBuscaDisponiveis"
            placeholder="Busca..."
            :append-icon="parametroBuscaDisponiveis.length ? 'mdi-close' : ''"
            @click:append="parametroBuscaDisponiveis = ''"
        />
        <v-card
            class="mx-auto"
            min-height="300"
            min-width="300"
            max-width="300"
            max-height="300"
            ref="disponiveis"
        >
          <v-list two-line height="300" class="overflow-y-auto">
            <v-list-item-group
                :value="Array.from(selecionadosDisponiveis)"
                active-class="primary--text"
                multiple
            >
              <template v-for="(item, index) in itemsDisponiveisBusca">
                <v-divider
                    v-if="index === 0"
                    :key="'dividierDisponiveisInicial'+index"
                ></v-divider>
                <handle-click :key="item.oid"
                              @single-click="selecionaItem(item, selecionadosDisponiveis)"
                              @double-click="adicionaItem(item, itemsDisponiveis, itemsEscolhidos)">
                  <v-list-item style="min-height: auto">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.titulo"></v-list-item-title>

                        <v-list-item-subtitle>
                          <span style="white-space: pre-line" class="text--primary">
                            {{ item.subTitulo }}
                          </span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                        <span style="white-space: pre-line">
                          {{ item.info }}
                        </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon
                            v-if="active"
                            color="yellow darken-3"
                        >
                          mdi-check-circle
                        </v-icon>
                        <v-icon
                            v-else
                            color="grey lighten-1"
                        >
                          mdi-checkbox-blank-circle
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </handle-click>

                <v-divider
                    :key="'dividerDisponiveis'+index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col align-self="center" cols="2">
        <v-sheet>
          <v-row class="mt-15 mb-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="restaurar">
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>Restaurar</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="adicionarTodos">
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </template>
              <span>Adicionar todos</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="adicionarSelecionados">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <span>Adicionar selecionados</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="removerSelecionados">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </template>
              <span>Remover selecionados</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="removerTodos">
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </template>
              <span>Remover todos</span>
            </v-tooltip>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col class="mt-1" cols="auto">
        <v-row justify="center" align="center" class="mb-1">
          Escolhidos
        </v-row>
        <v-text-field
            :disabled="itemsEscolhidosBusca.length === 0 && parametroBuscaEscolhidos.length === 0"
            class="mx-2"
            v-model="parametroBuscaEscolhidos"
            placeholder="Busca..."
            :append-icon="parametroBuscaEscolhidos.length ? 'mdi-close' : ''"
            @click:append="parametroBuscaEscolhidos = ''"
        />
        <v-card
            class="mx-auto"
            min-height="300"
            min-width="300"
            max-width="300"
            max-height="300"
        >
          <v-list two-line height="300" class="overflow-y-auto">
            <v-list-item-group
                v-model="selecionadosEscolhidosIndex"
                active-class="primary--text"
                multiple
            >
              <template v-for="(item, index) in itemsEscolhidosBusca">
                <v-divider
                    v-if="index === 0"
                    :key="'dividerEscolhidosInicial'+index"
                ></v-divider>
                <handle-click :key="item.oid"
                              @single-click="selecionaItem(item, selecionadosEscolhidos)"
                              @double-click="adicionaItem(item, itemsEscolhidos, itemsDisponiveis)">
                  <v-list-item style="min-height: auto">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.titulo"></v-list-item-title>

                        <v-list-item-subtitle>
                          <span style="white-space: pre-line" class="text--primary">
                            {{ item.subTitulo }}
                          </span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                        <span style="white-space: pre-line">
                          {{ item.info }}
                        </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon
                            v-if="active"
                            color="yellow darken-3"
                        >
                          mdi-check-circle
                        </v-icon>
                        <v-icon
                            v-else
                            color="grey lighten-1"
                        >
                          mdi-checkbox-blank-circle
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </handle-click>

                <v-divider
                    :key="'dividerEscolhidos'+index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col align-self="center" cols="1" v-if="ordenaEscolhidos">
        <v-sheet>
          <v-row class="mt-15 mb-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="posicionarAcima(true)"
                       :disabled="!itemsEscolhidos.length">
                  <v-icon>mdi-chevron-double-up</v-icon>
                </v-btn>
              </template>
              <span>Posicionar no inicio</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="posicionarAcima(false)"
                       :disabled="!itemsEscolhidos.length">
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>Posicionar acima</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="posicionarAbaixo(false)"
                       :disabled="!itemsEscolhidos.length">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>Posicionar abaixo</span>
            </v-tooltip>
          </v-row>
          <v-row class="my-2" align="center" justify="center">
            <v-tooltip right open-delay="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text outlined @click="posicionarAbaixo(true)"
                       :disabled="!itemsEscolhidos.length">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </template>
              <span>Posicionar no fim</span>
            </v-tooltip>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import _ from "lodash";
import HandleClick from "@/components/base/HandleClick";

export default {
  name: "SideBySideMultiSelect.vue",
  props: {
    value: {type: Array, required: true},
    items: {type: Array, required: false},
    ordenaEscolhidos: {type: Boolean, required: false, default: false},
    ordenacaoAutomatica: {type: Boolean, required: false, default: true},
    reiniciaEstado: {type: Boolean, required: false, default: true}
  },

  components: {HandleClick},

  data() {
    return {
      itemsDisponiveis: [],
      selecionadosDisponiveis: new Set(),
      itemsEscolhidos: this.value,
      selecionadosEscolhidos: new Set(),
      parametroBuscaDisponiveis: "",
      parametroBuscaEscolhidos: "",
      selecionadosEscolhidosIndex: [],
      valueCache: []
    }
  },

  created() {
    this.itemsDisponiveis = _.cloneDeep(this.items);
    if (this.reiniciaEstado) {
      this.itemsEscolhidos = [];
    } else {
      this.valueCache = _.cloneDeep(this.value);
    }
  },

  methods: {
    adicionaItem(item, listaOrigem, listaDestino) {
      listaDestino.push(item);
      this.removeObjetoLista(item, listaOrigem);
      this.ordenaLista(listaDestino, 'titulo');
    },

    selecionaItem(item, set) {
      set.has(item) ? set.delete(item) : set.add(item);
    },

    removeObjetoLista(objeto, lista) {
      let index = lista.indexOf(objeto);
      lista.splice(index, 1);
    },

    ordenaLista(lista, parametro) {
      if (this.ordenacaoAutomatica) {
        lista.sort((a, b) => (a[parametro] > b[parametro]) ? 1 : ((b[parametro] > a[parametro]) ? -1 : 0));
      }
    },

    filtraLista(lista, parametro) {
      return lista.filter(item => {
        if (item.titulo.toUpperCase().match(parametro.toUpperCase())) {
          return item;
        }
      });
    },

    restaurar() {
      this.removerTodos();
      this.itemsDisponiveis = _.cloneDeep(this.items);
      if (this.reiniciaEstado) {
        this.itemsEscolhidos = [];
      } else {
        this.itemsEscolhidos = _.cloneDeep(this.valueCache);
      }
      this.ordenaLista(this.itemsDisponiveis, 'titulo');
      this.selecionadosEscolhidos = new Set();
      this.selecionadosDisponiveis = new Set();
      this.selecionadosEscolhidosIndex = [];
      this.parametroBuscaDisponiveis = '';
      this.parametroBuscaEscolhidos = '';
    },

    adicionarSelecionados() {
      for (let item of this.selecionadosDisponiveis) {
        this.itemsEscolhidos.push(item);
        this.removeObjetoLista(item, this.itemsDisponiveis);
      }
      this.ordenaLista(this.itemsEscolhidos, "titulo");
      this.selecionadosDisponiveis = new Set();
      this.selecionadosEscolhidos = new Set();
    },

    removerSelecionados() {
      for (let item of this.selecionadosEscolhidos) {
        this.itemsDisponiveis.push(item);
        this.removeObjetoLista(item, this.itemsEscolhidos);
      }
      this.ordenaLista(this.itemsDisponiveis, "titulo");
      this.selecionadosDisponiveis = new Set();
      this.selecionadosEscolhidos = new Set();
      this.selecionadosEscolhidosIndex = [];
    },

    adicionarTodos() {
      this.itemsDisponiveisBusca.forEach(item => {
        this.selecionadosDisponiveis.add(item);
      });
      this.adicionarSelecionados();
    },

    removerTodos() {
      this.itemsEscolhidosBusca.forEach(item => {
        this.selecionadosEscolhidos.add(item);
      });
      this.removerSelecionados();
    },

    posicionarAcima(inicio) {
      let indexesEscolhidos = _.cloneDeep(this.selecionadosEscolhidosIndex);

      let itemsAux = [];
      this.selecionadosEscolhidosIndex = [];

      if (inicio) {
        this.selecionadosEscolhidos.forEach(s => itemsAux.push(s));
        this.itemsEscolhidos.forEach(item => {
          if (!indexesEscolhidos.includes(this.itemsEscolhidos.indexOf(item))) {
            itemsAux.push(item);
          }
        })
        this.selecionadosEscolhidos = new Set();
        this.selecionadosEscolhidosIndex = [];
      } else {
        indexesEscolhidos.forEach(i => {
          let novoIndex = i-1;
          if (novoIndex < 0) {
            novoIndex = i;
          }
          itemsAux = this.array_move(this.itemsEscolhidos, i, novoIndex);
          this.selecionadosEscolhidosIndex.push(novoIndex)
        });
      }
      this.itemsEscolhidos = _.cloneDeep(itemsAux);
    },

    posicionarAbaixo(fim) {
      let indexesEscolhidos = _.cloneDeep(this.selecionadosEscolhidosIndex);

      let itemsAux = [];
      this.selecionadosEscolhidosIndex = [];

      if (fim) {
        this.itemsEscolhidos.forEach(item => {
          if (!indexesEscolhidos.includes(this.itemsEscolhidos.indexOf(item))) {
            itemsAux.push(item);
          }
        })
        this.selecionadosEscolhidos.forEach(s => itemsAux.push(s));
        this.selecionadosEscolhidos = new Set();
        this.selecionadosEscolhidosIndex = [];
      } else {
        indexesEscolhidos.forEach(i => {
          let novoIndex = i+1;
          if (novoIndex >= this.itemsEscolhidos.length) {
            novoIndex = i;
          }
          itemsAux = this.array_move(this.itemsEscolhidos, i, novoIndex);
          this.selecionadosEscolhidosIndex.push(novoIndex)
        });
      }
      this.itemsEscolhidos = _.cloneDeep(itemsAux);
    },

    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        new_index = old_index;
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }

  },

  watch: {
    itemsEscolhidos: {
      handler() {
        this.$emit("input", this.itemsEscolhidos);
      },
      deep: true
    },

    items: {
      handler() {
        this.itemsDisponiveis = _.cloneDeep(this.items);
        this.selecionadosDisponiveis = new Set();
      },
      deep: true
    }

  },

  computed: {
    itemsDisponiveisBusca() {
      if (this.parametroBuscaDisponiveis.length >= 3) {
        return this.filtraLista(this.itemsDisponiveis, this.parametroBuscaDisponiveis);
      } else {
        return this.itemsDisponiveis;
      }
    },

    itemsEscolhidosBusca() {
      if (this.parametroBuscaEscolhidos.length >= 3) {
        return this.filtraLista(this.itemsEscolhidos, this.parametroBuscaEscolhidos);
      } else {
        return this.itemsEscolhidos;
      }
    }
  }
}
</script>

<style scoped>

</style>
